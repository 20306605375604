import { createApp } from 'vue';
import App from './App.vue';
import router from './route';
import install from './install';
import { createPinia } from 'pinia';
import 'animate.css/animate.min.css';
import 'vant/lib/index.css';
import { Lazyload } from 'vant';
const pinia = createPinia();
const app = createApp(App);
app.use(Lazyload);
app.use(install);
app.use(router);
app.use(pinia);
app.mount('#app');

import axios from 'axios';
// 将 router 实例挂载到 Axios 配置中
axios.defaults.router = router;

// 一下增加js统计代码

/**
 * 将字符串中的特殊字符转义为 HTML 实体
 * @param {string} str - 需要转义的字符串
 * @returns {string} - 转义后的字符串
 */
const unescapeHTML = (escapedString) => {
	var map = {
		'&lt;': '<',
		'&gt;': '>',
		'&amp;': '&',
		'&quot;': '"',
		'&#39;': "'"
	};

	return escapedString.replace(/&lt;|&gt;|&amp;|&quot;|&#39;/g, function (m) {
		return map[m];
	});
};
import { useIndexStore } from './store';
import { storeToRefs } from 'pinia';
import { windowHeight } from 'vant/lib/utils';
const userIndex = useIndexStore();
const { config } = storeToRefs(userIndex);
router.afterEach((to, from, next) => {
	loaddingCnzz();
	loaddingOpenShare();

	// loaddingIptvlm();

	loadding51La(); // 51拉js代码。
});

const loaddingCnzz = () => {
	setTimeout(() => {
		if (config.value?.statistics_js && config.value?.statistics_js.length > 0) {
			let tj = unescapeHTML(config.value?.statistics_js);
			// console.log("tj", tj,typeof(tj));
			let newStr = tj.replace(/<script>/gi, '').replace(/<\/script>/gi, '');
			// alert(newStr)
			eval(newStr);
		}

		// alert(123)
		// 此处粘贴百度统计复制过来的代码
		//   var _hmt = _hmt || [];
		//   (function() {
		//     var hm = document.createElement("script");
		//     hm.src = "https://hm.baidu.com/hm.js?************";
		//     var s = document.getElementsByTagName("script")[0];
		//     s.parentNode.insertBefore(hm, s);
		// })();
	}, 2000);
};

const loaddingOpenShare = () => {
	// OpenShare 统计代码  https://159cloud.com/#/help
	var _hmt = _hmt || [];
	(function () {
		var hm = document.createElement('script');
		hm.src = 'https://os.sdwok.cn/open/os2.js';
		var s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(hm, s);

		setTimeout(() => {
			var m = new window.OpenShare({ appKey: 'xlF0SHRcVrbEfJv' });
			function DownSoft() {
				m.wakeupOrInstall;
			}
		}, 2000);
	})();
};

// 加载js
const loaddingIptvlm = () => {
	var _hmt = _hmt || [];
	(function () {
		var hm = document.createElement('script');
		hm.src = 'https://souka.iptvlm.com/js/c1f9295a29752572.js';
		var s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(hm, s);
	})();
};

const loadding51La = () => {
	!(function (p) {
		'use strict';
		!(function (t) {
			var s = window,
				e = document,
				i = p,
				c = ''.concat('https:' === e.location.protocol ? 'https://' : 'http://', 'sdk.51.la/js-sdk-pro.min.js'),
				n = e.createElement('script'),
				r = e.getElementsByTagName('script')[0];
			(n.type = 'text/javascript'), n.setAttribute('charset', 'UTF-8'), (n.async = !0), (n.src = c), (n.id = 'LA_COLLECT'), (i.d = n);
			var o = function () {
				s.LA.ids.push(i);
			};
			s.LA ? s.LA.ids && o() : ((s.LA = p), (s.LA.ids = []), o()), r.parentNode.insertBefore(n, r);
		})();
	})({ id: 'KZV939UYhkYcEGkJ', ck: 'KZV939UYhkYcEGkJ' });
};

const setViewportScale = () => {
	const pageWidth = window.innerWidth;
	const pageHeight = window.innerHeight;
	const targetWidth = 375;
	const isPC = pageWidth > 1000;
	const scale = isPC ? pageWidth / targetWidth : 0.3;

	console.log('scale', scale);

	const app = document.getElementById('app');
	if (app && isPC) {
		document.getElement;
		app.style.transform = `scale(${scale})`;
		app.style.width = `${targetWidth}px`;
		app.style.height = `${pageHeight}px`; // 你可以根据需要调整高度
	}
};
// setViewportScale();

function resizeContainer() {
	const docHeight = document.body.clientHeight;
	const docWidth = document.body.clientWidth;
	console.log('docHeight', docHeight, 'docWidth', docWidth);

	if (docWidth > 750) {
		const container = document.getElementById('app');
		// let scale = Math.min(window.innerWidth / 375, window.innerHeight / 667);

		let scale = 375 / docWidth;
		console.log('scale', scale);

		container.style.transform = `scale(${scale})`;
		container.style.transformOrigin = `top center`;
		// document.body.style.height = window.innerHeight / scale + 'px';
		container.style.height = window.innerHeight / scale + 'px';
		// 隐藏底部tabbar
		document.getElementById('tabbarList').style.display = 'none';
	}
}

window.addEventListener('resize', resizeContainer);
window.addEventListener('load', resizeContainer);
