import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-09bbe25e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "list1"
};
const _hoisted_2 = {
  key: 2,
  class: "list4"
};
const _hoisted_3 = {
  key: 0,
  class: "list5"
};
const _hoisted_4 = {
  class: "pop-content"
};
const _hoisted_5 = {
  class: "list5-div"
};
const _hoisted_6 = {
  class: "img-div"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  key: 4,
  class: "list6"
};
const _hoisted_9 = {
  class: "img-div"
};
const _hoisted_10 = {
  class: "title"
};
const _hoisted_11 = {
  key: 5,
  class: "notice-div"
};
const _hoisted_12 = ["onClick"];
const _hoisted_13 = {
  class: "img-div"
};
const _hoisted_14 = {
  class: "title"
};
const _hoisted_15 = {
  key: 7,
  class: "ads-text"
};
const _hoisted_16 = {
  key: 8,
  class: "ads-text ads-text28"
};
const _hoisted_17 = {
  key: 9,
  class: "list12"
};
const _hoisted_18 = {
  key: 10,
  class: "list13"
};
const _hoisted_19 = {
  key: 0,
  class: "list14"
};
const _hoisted_20 = {
  key: 0,
  class: "list15"
};
const _hoisted_21 = {
  class: "right-div"
};
const _hoisted_22 = {
  key: 13,
  class: "list16"
};
const _hoisted_23 = {
  class: "item-title"
};
const _hoisted_24 = {
  class: "item-desc"
};
const _hoisted_25 = {
  key: 14,
  class: "list20"
};
const _hoisted_26 = {
  key: 15,
  class: "list21"
};
const _hoisted_27 = {
  class: "item-right"
};
const _hoisted_28 = {
  class: "item-title"
};
const _hoisted_29 = {
  class: "item-desc"
};
const _hoisted_30 = {
  class: "right-down"
};
const _hoisted_31 = {
  key: 16,
  class: "dajiadouzaiwan"
};
const _hoisted_32 = {
  class: "info"
};
const _hoisted_33 = {
  class: "item-title"
};
const _hoisted_34 = {
  class: "rating"
};
const _hoisted_35 = ["src"];
const _hoisted_36 = {
  class: "item-desc"
};
const _hoisted_37 = {
  class: "down-div"
};
const _hoisted_38 = {
  class: "left"
};
const _hoisted_39 = {
  class: "name"
};
import { ref, computed, watch } from 'vue';
import { useIndexStore } from '../store';
import { storeToRefs } from 'pinia';
import { goToTheRandomAddress, isDev, downloadApp, isPWA } from '@/utils';
import api from '../api';
export default {
  __name: 'AdsPictures',
  props: {
    list: {
      default() {
        return [];
      }
    },
    type: {
      default: 1
    },
    number: {
      default: 0 //广告显示的个数，默认0全部
    },

    random: {
      default: false //是否打乱数组
    }
  },

  emits: ['close', 'onload'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const userIndex = useIndexStore();
    const {
      allAdsList,
      config
    } = storeToRefs(userIndex);
    const swiper = ref();
    const seconds = ref(5 * 1000);
    const value = ref(5);
    const showDownload = ref(true);

    // 数组打乱，随机返回数据
    const shuffleArray = array => {
      // 从最后一个元素开始遍历，直至第一个元素
      for (let i = array.length - 1; i > 0; i--) {
        // 生成随机索引，范围是0到i（包括i）
        const j = Math.floor(Math.random() * (i + 1));
        // 交换当前元素与随机选中的元素
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };
    const adsList = computed(() => {
      let list = allAdsList.value.filter(v => v.class_id == props.type);
      // 调试
      if (isDev() && props.type === 21) {
        return allAdsList.value.filter(v => v.class_id == 1);
      }

      // 如果传递了个数。
      if (props.number != 0 && list.length) {
        //console.log('list-y', list)
        //console.log('props.number', props.number, 'props.type', props.type)
        list = list.splice(0, props.number);
        //console.log('list-slice', list)
      }

      //是否随机
      if (props.random && list.length) {
        list = shuffleArray(list); //打乱数组
      }

      return list;
    });
    const clickImg = item => {
      goToTheRandomAddress(item.url.split(','));
      if (item.url) {
        reportAds();
      }
    };

    // 首页弹窗广告 默认展示第一个 关闭当前展示下一个 当前为最后一个关闭弹窗
    const popAd = ref({
      show: false,
      showIndex: 0,
      list: computed(() => {
        let filterList = allAdsList.value.filter(v => v.class_id == 5);

        // 随机1个
        filterList = shuffleArray(filterList);
        filterList = filterList.slice(0, 1);
        // console.log('popAd-list', filterList)
        return filterList;
      }),
      close: index => {
        // popAd.value.showIndex = index + 1
        // if (index === popAd.value.list.length - 1) {
        //     popAd.value.show = false
        //     emits('onload')
        // }

        popAd.value.show = false;
      }
    });
    const reportAds = async () => {
      await api.reportAds({
        ad_ids: props.type,
        report_type: 1
      });
    };
    watch(() => popAd.value.list, v => {
      if (props.type === 5) {
        popAd.value.show = true;

        //console.log('document.referrer', document.referrer)

        // if (sessionStorage.getItem('popAd')) return
        // if (v.length > 0) {
        //     popAd.value.show = true
        // } else {
        //     emits('onload')
        // }
        sessionStorage.setItem('popAd', 0);
      }
    });
    const show14 = ref(true);
    const isResize = ref(false);
    const change = () => {
      if (!isResize.value) {
        swiper.value?.resize();
        isResize.value = true;
      }
    };

    // 判断当前是安卓还是IOS，取渠道后台配置的 下载地址
    const clickDownApp = () => {
      let android_download_urls = [...config.value.android_download_urls.split(',')];
      let ios_download_urls = [...config.value.ios_download_urls.split(',')];
      console.log('android_download_urls', android_download_urls, 'ios_download_urls', ios_download_urls);
      downloadApp(ios_download_urls, android_download_urls);
    };
    return (_ctx, _cache) => {
      const _component_van_loading = _resolveComponent("van-loading");
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_van_text_ellipsis = _resolveComponent("van-text-ellipsis");
      const _component_van_tag = _resolveComponent("van-tag");
      const _component_van_count_down = _resolveComponent("van-count-down");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_rate = _resolveComponent("van-rate");
      return _openBlock(), _createElementBlock(_Fragment, null, [[1, 22, 33, 35, 36, 25].includes(__props.type) && adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_swipe, {
        ref_key: "swiper",
        ref: swiper,
        class: "my-swipe",
        autoplay: 3000,
        "indicator-color": "white",
        onChange: change
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createVNode(_component_van_image, {
              "lazy-load": "",
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item),
              fit: "contain"
            }, {
              loading: _withCtx(() => [_createVNode(_component_van_loading, {
                type: "spinner",
                size: "20"
              })]),
              _: 2
            }, 1032, ["src", "onClick"])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }, 512)])) : _createCommentVNode("", true), [3].includes(__props.type) && adsList.value.length ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [adsList.value.length ? (_openBlock(), _createBlock(_component_van_swipe, {
        key: 0,
        class: "my-swipe3",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createVNode(_component_van_image, {
              "lazy-load": "",
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, {
              loading: _withCtx(() => [_createVNode(_component_van_loading, {
                type: "spinner",
                size: "20"
              })]),
              _: 2
            }, 1032, ["src", "onClick"])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), [4].includes(__props.type) && adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createVNode(_component_van_image, {
          "lazy-load": "",
          src: item.icon,
          alt: "",
          onClick: $event => clickImg(item),
          fit: "fill"
        }, {
          loading: _withCtx(() => [_createVNode(_component_van_loading, {
            type: "spinner",
            size: "20"
          })]),
          _: 2
        }, 1032, ["src", "onClick"])]);
      }), 128))])) : _createCommentVNode("", true), [5].includes(__props.type) && adsList.value.length ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 3
      }, [popAd.value.show ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_van_popup, {
        show: popAd.value.show,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => popAd.value.show = $event),
        name: "bounce",
        duration: "0.5",
        "z-index": "3000",
        "close-on-click-overlay": false,
        class: "pop-ad",
        style: {
          width: '80%',
          'max-height': '80%',
          background: 'none'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_Transition, {
            key: index,
            name: "animate__animated animate__zoomOut",
            "enter-active-class": "animate__zoomIn",
            "leave-active-class": "animate__zoomOut"
          }, {
            default: _withCtx(() => [_withDirectives(_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
              class: "close-icon-div",
              onClick: $event => popAd.value.close(index)
            }, [_createVNode(_component_van_icon, {
              class: "close-icon",
              name: "cross"
            })], 8, _hoisted_7), _createVNode(_component_van_image, {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item),
              fit: "fill"
            }, {
              loading: _withCtx(() => [_createVNode(_component_van_loading, {
                type: "spinner",
                size: "20"
              })]),
              _: 2
            }, 1032, ["src", "onClick"])])], 512), [[_vShow, popAd.value.showIndex === index]])]),
            _: 2
          }, 1024);
        }), 128))])]),
        _: 1
      }, 8, ["show"])])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), __props.type === 6 && adsList.value.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_van_image, {
          "lazy-load": "",
          src: item.icon,
          alt: "",
          onClick: $event => clickImg(item)
        }, {
          loading: _withCtx(() => [_createVNode(_component_van_loading, {
            type: "spinner",
            size: "20"
          })]),
          _: 2
        }, 1032, ["src", "onClick"])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_van_text_ellipsis, {
          content: item.name
        }, null, 8, ["content"])])]);
      }), 128))])) : _createCommentVNode("", true), [9].includes(__props.type) && adsList.value.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          onClick: $event => clickImg(item)
        }, _toDisplayString(item.name), 9, _hoisted_12);
      }), 128))])])) : _createCommentVNode("", true), __props.type === 10 && __props.list.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 6
      }, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "list10",
          key: index
        }, [_createElementVNode("div", _hoisted_13, [_createVNode(_component_van_image, {
          class: "img",
          fit: "fill",
          src: item.icon,
          onClick: $event => clickImg(item)
        }, {
          loading: _withCtx(() => [_createVNode(_component_van_loading, {
            type: "spinner",
            size: "20"
          })]),
          _: 2
        }, 1032, ["src", "onClick"])]), _createElementVNode("div", _hoisted_14, _toDisplayString(item.name), 1)]);
      }), 128)) : _createCommentVNode("", true), [11, 34, 37].includes(__props.type) && adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "rgb(34, 34, 34)",
          "text-color": "rgb(187, 187, 187)",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), [28].includes(__props.type) && adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_16, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "rgb(204, 229, 255)",
          "text-color": "rgb(0, 64, 133)",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 12 && adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_17, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "#222",
          "text-color": "#c6c6c6",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 13 && adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_18, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "#222",
          "text-color": "#c6c6c6",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 14 && adsList.value.length ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 11
      }, [show14.value ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createVNode(_component_van_swipe, {
        class: "my-swipe14",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createVNode(_component_van_image, {
              "lazy-load": "",
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, {
              loading: _withCtx(() => [_createVNode(_component_van_loading, {
                type: "spinner",
                size: "20"
              })]),
              _: 2
            }, 1032, ["src", "onClick"]), _createElementVNode("div", {
              class: "popup-close-icon-div",
              onClick: _cache[1] || (_cache[1] = $event => show14.value = false)
            }, [_createVNode(_component_van_icon, {
              name: "cross",
              class: "popup-close-icon"
            })])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), __props.type === 15 && adsList.value.length ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 12
      }, [adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createElementVNode("span", null, [_createVNode(_component_van_count_down, {
        time: seconds.value,
        format: "ss秒",
        onFinish: _cache[2] || (_cache[2] = $event => _ctx.$emit('close'))
      }, null, 8, ["time"])]), _createElementVNode("span", {
        onClick: _cache[3] || (_cache[3] = $event => _ctx.$emit('close'))
      }, "跳过")]), adsList.value.length ? (_openBlock(), _createBlock(_component_van_swipe, {
        key: 0,
        class: "my-swipe15",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createVNode(_component_van_image, {
              "lazy-load": "",
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, {
              loading: _withCtx(() => [_createVNode(_component_van_loading, {
                type: "spinner",
                size: "20"
              })]),
              _: 2
            }, 1032, ["src", "onClick"]), _createVNode(_component_van_icon, {
              name: "cross",
              class: "popup-close-icon"
            })]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), [16, 17, 18, 19].includes(__props.type) && adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_22, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: index
        }, [_createElementVNode("div", null, [_createVNode(_component_van_image, {
          src: item.icon,
          fit: "fill"
        }, {
          loading: _withCtx(() => [_createVNode(_component_van_loading, {
            type: "spinner",
            size: "20"
          })]),
          _: 2
        }, 1032, ["src"])]), _createElementVNode("div", _hoisted_23, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_24, _toDisplayString(item.remark || item.name), 1), _createVNode(_component_van_button, {
          type: "primary",
          color: "#ff9002",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode("立即下载")]),
          _: 2
        }, 1032, ["onClick"])]);
      }), 128))])) : _createCommentVNode("", true), [20].includes(__props.type) && adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_25, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: index
        }, [_createVNode(_component_van_image, {
          src: item.icon,
          fit: "fill",
          onClick: $event => clickImg(item)
        }, {
          loading: _withCtx(() => [_createVNode(_component_van_loading, {
            type: "spinner",
            size: "20"
          })]),
          _: 2
        }, 1032, ["src", "onClick"])]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 21 && adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_26, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "game-item",
          key: index
        }, [_createElementVNode("div", null, [_createVNode(_component_van_image, {
          src: item.icon,
          fit: "fill"
        }, {
          loading: _withCtx(() => [_createVNode(_component_van_loading, {
            type: "spinner",
            size: "20"
          })]),
          _: 2
        }, 1032, ["src"])]), _createElementVNode("div", _hoisted_27, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_28, _toDisplayString(item.name), 1), _createElementVNode("div", null, [_createVNode(_component_van_rate, {
          modelValue: value.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => value.value = $event),
          size: 12,
          color: "#ffd21e",
          "void-icon": "star",
          "void-color": "#eee"
        }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_29, _toDisplayString(item.remark || item.name), 1)])]), _createElementVNode("div", _hoisted_30, [_createVNode(_component_van_image, {
          "lazy-load": "",
          src: "../assets/app-download-btn.png",
          onClick: $event => clickImg(item)
        }, {
          loading: _withCtx(() => [_createVNode(_component_van_loading, {
            type: "spinner",
            size: "20"
          })]),
          _: 2
        }, 1032, ["onClick"])])]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 29 && adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_31, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "dj-item",
          key: index
        }, [_createElementVNode("div", null, [_createVNode(_component_van_image, {
          src: item.icon,
          fit: "fill"
        }, {
          loading: _withCtx(() => [_createVNode(_component_van_loading, {
            type: "spinner",
            size: "20"
          })]),
          _: 2
        }, 1032, ["src"])]), _createElementVNode("div", _hoisted_32, [_createElementVNode("div", _hoisted_33, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_34, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5], (item, index) => {
          return _createElementVNode("img", {
            key: index,
            src: require('../assets/star-icon.png'),
            alt: ""
          }, null, 8, _hoisted_35);
        }), 64))]), _createElementVNode("div", _hoisted_36, _toDisplayString(item.remark || item.name), 1)]), _createVNode(_component_van_image, {
          class: "downbtn",
          onClick: $event => clickImg(item),
          src: require('../assets/app-download-btn.png'),
          fit: "fill"
        }, null, 8, ["onClick", "src"])]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 32 || __props.type === 26 && adsList.value.length ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 17
      }, _renderList(adsList.value, (item, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "down-app",
          key: index
        }, [_createElementVNode("div", _hoisted_37, [_createElementVNode("div", _hoisted_38, [_createVNode(_component_van_icon, {
          name: "cross",
          class: "popup-close-icon",
          onClick: _cache[5] || (_cache[5] = $event => showDownload.value = false)
        }), _createVNode(_component_van_image, {
          "lazy-load": "",
          class: "logo-img",
          src: item.icon,
          alt: ""
        }, {
          loading: _withCtx(() => [_createVNode(_component_van_loading, {
            type: "spinner",
            size: "20"
          })]),
          _: 2
        }, 1032, ["src"]), _createElementVNode("div", _hoisted_39, [_createElementVNode("div", null, _toDisplayString(item.name), 1), _createElementVNode("div", null, _toDisplayString(item.remark || "下载app免费观看百万视频"), 1)])]), _createElementVNode("div", null, [_createVNode(_component_van_button, {
          type: "primary",
          size: "small",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode("立即下载")]),
          _: 2
        }, 1032, ["onClick"])])])])), [[_vShow, showDownload.value && !_unref(isPWA)()]]);
      }), 128)) : _createCommentVNode("", true)], 64);
    };
  }
};